import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div
      className="leading-normal"
      style={{
        maxWidth: 640,
      }}
    >
      <h1 className="font-serif text-5xl text-bold">404 Page Not Found</h1>
      <p>
        Sorry, but the page you requested cannot be found. A New website is coming soon, but if you
        wish to contact Colin regarding the page you requested please email{' '}
        <a href="mailto:colin.genlloud@gmail.com">colin.genlloud@gmail.com</a>.
      </p>
    </div>
  </Layout>
);

export default NotFoundPage;
